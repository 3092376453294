@media (max-width: 1999px) and (min-width: 577px) {
  .productImage {
    width: 60vmin;
    height: 60vmin;
  }
  .shopCard {
    width: 50vmin;
  }
  .scroller:hover .scroll-controller {
    display: block;
  }
  .scroller::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .scroll-controller {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .productImage {
    width: 92vmin;
    height: 92vmin;
  }
  .shopCard {
    width: 100vmin;
  }
  .scroll-controller {
    display: none;
  }
  .scroller::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
}
 
@media screen and (min-width: 1200px) {
  .productImage {
    width: 49vmin;
    height: 49vmin;
  }
  .shopCard {
    width: 30vmin;
  }
  .scroll-controller {
    display: none;
  }
  .scroller:hover .scroll-controller {
    display: block;
  }
  .scroller::-webkit-scrollbar {
    display: none;
  }

}

.scroller-item {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.scroller-controller-icon {
  color: #f4f5f5;
  background-color: #7f7a76;
  border-radius: 50%;
  opacity: 0.8;
}

.scroller-item-container {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}