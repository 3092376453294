@media (max-width: 1999px) and (min-width: 577px) {
    .scroller:hover .scroll-controller {
      display: block;
    }
    .scroller::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    .scroll-controller {
      display: none;
    }
  
    .shop-Thumbnail .img-container {
      width: 24vmin;
      height:0px;
      padding-bottom:100%;
      position:relative;
    }
  }
  
  @media screen and (max-width: 576px) {
    .scroll-controller {
      display: none;
    }
    .scroller::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  
    .shop-Thumbnail .img-container {
      width: 43vmin;
      height: 0px;
      padding-bottom: 100%;
      position: relative;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .scroll-controller {
      display: none;
    }
    .scroller:hover .scroll-controller {
      display: block;
    }
    .scroller::-webkit-scrollbar {
      display: none;
    }
  
    .shop-Thumbnail .img-container {
      width: 21vmin;
      height: 0px;
      padding-bottom: 100%;
      position: relative;
    }
  }
  
  .scroller-item{
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  
  .shopCard {
    max-width: 390px;
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  
  .shop-Thumbnail img{
    width:100%;
    height:100%;
    position:absolute;
  }
  
  .productImage {
    max-width: 390px;
    max-height: 390px;
    width: 100vw;
    height: 100vw;
  }
  
  .ownCoupon-container {
    width: 270px;
    height: 80px;
    overflow: auto;
    border-radius: 5px;
    box-shadow:  0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .ownCoupon-image{
    width: 70px;
    height: 70px;
  }
  
  .product_description_close {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  
  .shop_popup_tag_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    background: #fceabb;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f8b500, #fceabb);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f8b500, #fceabb); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  }