.products-carousel-container {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.products-carousel-addButton-container {
    position: absolute;
    right: 2%;
    top: 45%;
    z-index: 20;
}

.products-carousel-image{
    width: 100%;
    /* height: auto; */
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
}

.products-carousel-icon-container{
    position: absolute;
    right: 8%;
    top: 5%;
    z-index: 20;
}

.product-init-item-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    width: 300px;
    height: 300px;
    color: #0091c9;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230091C9FF' stroke-width='10' stroke-dasharray='17%2c 8%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}