
@media (max-width: 1999px) and (min-width: 577px) {
	.sidebar {
		display: none;
	}
  }
  
  @media screen and (max-width: 576px) {
	.sidebar {
		display: none;
	}
  }
   
  @media screen and (min-width: 1500px) {
	.sidebar {
		position:fixed;
		top:56px;
		left:0;
		display:flex;
		flex-direction:column;
		justify-content: space-between;
		width:15%;
		height: 100vh;
		overflow-y: auto;
		/* background-color: #9DAAB6; */
		padding:2rem 1rem 1rem 1rem;
		transition: transform 1s;
	}
  
  }